import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import logo from './logo.png';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const NavBar = ({ className }) => {
    return (
        <Header
            className={`${className} ${
                // don't allow students to change their password or other info
                Fortress.user.acting.role.handle === 'student' ? style.hideUpdateProfile : ''
            }`}
            logo={<img src={logo} alt="Computer Games for Learning Study" className={style.logo} />}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
