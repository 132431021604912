import React from 'react';
import _get from 'lodash/get';
import { Redirect } from 'cccisd-react-router';

const Fortress = window.cccisd.fortress;

const EndpointRedirect = () => {
    const deploymentEndpoint = _get(Fortress, 'user.acting.role_data.deployment_endpoint', null);
    if (deploymentEndpoint) {
        return <Redirect to={`/survey/${deploymentEndpoint}`} />;
    }
    return <Redirect to="/" />;
};

export default EndpointRedirect;
