import crooksAndRobbers from 'cccisd-game-crooks-and-robbers';
import choiceDelay from 'cccisd-game-selee-choice-delay';
import {
    components as eeComponents,
    componentTemplates as eeComponentTemplates,
    playerTemplates as eePlayerTemplates,
} from 'cccisd-laravel-selwebee';
import {
    components as leComponents,
    componentTemplates as leComponentTemplates,
    playerTemplates as lePlayerTemplates,
} from 'cccisd-laravel-selweble';

export default {
    components: [
        crooksAndRobbers,
        choiceDelay,
        eeComponents.AboutStudentMC,
        eeComponents.AboutStudentMatrix,
        eeComponents.Assistant,
        eeComponents.Intro,
        eeComponents.FT,
        eeComponents.FTIntro,
        leComponents.Assistant,
    ],
    templates: {
        components: {
            matrix: [
                leComponentTemplates.SPSelWebMatrix,
                leComponentTemplates.SPSelWebFullMatrix,
                leComponentTemplates.SelWebMatrix,
                leComponentTemplates.SelWebFullMatrix,
            ],
            multipleChoice: [
                eeComponentTemplates.CometAndBearMC,
                eeComponentTemplates.CometOrBearMC,
                eeComponentTemplates.SocialAwarenessMC,
                eeComponentTemplates.SocialPicMC,
                eeComponentTemplates.SocialTextMC,
                leComponentTemplates.SPSelWebMC,
                leComponentTemplates.SPSelWebCEMC,
                leComponentTemplates.SelWebMC,
                leComponentTemplates.SelWebCEMC,
                leComponentTemplates.SelWebMatrixMC,
            ],
            video: [eeComponentTemplates.NoButtonVideo],
        },
        players: {
            flow: [
                eePlayerTemplates.EE,
                eePlayerTemplates.Vignette,
                eePlayerTemplates.RocketGame,
                eePlayerTemplates.Video,
                lePlayerTemplates.SPSelWeb,
                lePlayerTemplates.SPSelWebCE,
                lePlayerTemplates.SPSelWebNewPT,
                lePlayerTemplates.SelWeb,
                lePlayerTemplates.SelWebCE,
                lePlayerTemplates.SelWebNewPT,
                lePlayerTemplates.SelfReport,
                lePlayerTemplates.TeacherRating,
            ],
        },
    },
};
